import { Box, Typography } from "@mui/material";

const Description = () => {
    return (
        <Box sx={{textAlign: 'center'}}>
            <Typography sx={{mt: 1 }}>
                I also like do do some DIY projects along the way.
            </Typography>
            
        </Box>
    );
};

export default Description;
